@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: 'Inter', sans-serif;
  /* background-color: #fff386; */
}

.connectNav {
  padding: 4px 8px;
  cursor: pointer;
}

.connectNav:hover {
  background-color: #eeeeee;
  backdrop-filter: blur(50px);
}

.connectNav:active {
  background-color: #dddddd;
  backdrop-filter: blur(50px);
}

.fullHeight {
  height: 100vh;
}

a {
  color: inherit;
}

.blackBg {
  background-color: black;
  color: white;
}

.limeBg {
  background-color: #cff469;
  color: #503658;
}

.purpleBg {
  background-color: #503658;
  color: #cff469;
}

.App {
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: flex-start;

  position: absolute;
  top: 0px;
  left: 0px;
  min-height: 100vh;
  width: 100%;
}

.numGeneratedText {
  padding: 16px;
  font-family: Menlo;
}

.justifyRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  width: 128px;
  height: 32px;
}


h1 {
  font-size: 80px;
  margin-block: 2rem;
}

h2 {
  font-size: 64px;
}

h3 {
  font-size: 40px;
}

h5 {
  font-size: 24px;
}

h4 {
  font-weight: 400;
}

.content {

}

.connectWalletButton {
  /* Auto Layout */

  padding: 16px;

  background: #202f72;
  border-radius: 4px;

  cursor: pointer;
  display: flex;
  flex-direction: row;

  color: white;
  font-family: Inter;
  font-style: normal;

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.holoBackground {
  background: linear-gradient(225deg, #E6A3D7 0%, #D6F3C6 30.72%, #ADFCDD 51.31%, #95BDEA 75.79%, #DBBEFF 100%);
}

.shapesContainer {
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;

  overflow: hidden;
  position: absolute;
  text-align: justify;

  pointer-events: none;

  color: #e377bb;
}

.shape {
  position: absolute;
}

/* SHAPE 1 */

.shape1 {
  left: -1325px;
  bottom: -1034px;
}

@media (max-width: 1199px) {
  .shape1 {
    width: 1970px;
    height: 1404px;
    left: -1176px;
    bottom: -924px;
  }
}

@media (max-width: 767px) {
  .shape1 {
    width: 1443px;
    height: 1028px;
    left: -952px;
    bottom: -639px;
  }
}

/* SHAPE 2 */

.shape2 {
  right: -1626px;
  top: -1548px;
}

@media (max-width: 1199px) {
  .shape2 {
    display: none;
  }
}

@media (max-width: 767px) {
  .shape2 {
    display: none;
  }
}

/* SHAPE 3 */

.shape3 {
  display: none;
}

@media (max-width: 1199px) {
  .shape3 {
    display: block;
    width: 2100px;
    height: 1331px;
    top: -890px;
    right: -1250px;
  }
}

@media (max-width: 767px) {
  .shape3 {
    display: block;
    width: 1538px;
    height: 975px;
    top: -700px !important;
    right: -893px !important;
  }
}

/* SHAPE 4 */

.shape4 {
  right: -2183px;
  bottom: -895px;
}

@media (max-width: 1199px) {
  .shape4 {
    width: 2100px;
    height: 1331px;
    right: -1928px;
    bottom: -812px;
  }
}

@media (max-width: 767px) {
  .shape4 {
    width: 1538px;
    height: 975px;
    right: -1469px;
    bottom: -689px;
  }
}


.priceButton {
  /* White */

  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: Menlo;
  font-style: normal;
  font-weight: normal;

  min-width: 72px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1 1 100%;

  cursor: pointer;
}

.nftShadow {
  box-shadow: 0px 8px 25px 8px rgba(0, 0, 0, 0.12);
}

.priceButton:hover {
  background-color: #f0f0f0;
  border: 1px solid lightblue;
}

.priceButton:active {
  border: 1px solid lightblue;
  background-color: #e0e0e0;
}

.priceInput {
  background: #ffffff;
  /* Off Black */
  border: 1px solid #1a1c21;
  box-sizing: border-box;
  border-radius: 4px;

  width: 100%;
  height: 36px;

  font-family: Menlo;
  font-style: normal;
  font-weight: normal;

  padding-left: 8px;
}

/* prepare wrapper element */
.inputWrapper {
  display: inline-block;
  position: relative;
}

.times {
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: -0.05em;
  font-style: italic;
}

/* position the unit to the right of the wrapper */
.inputWrapper::after {
  position: absolute;
  top: 2px;
  right: 0.5em;
  transition: all 0.05s ease-in-out;
}

/* handle Firefox (arrows always shown) */
@supports (-moz-appearance: none) {
  .inputWrapper::after {
    right: 1.5em;
  }
}

/* set the unit abbreviation for each unit class */
.ethInputWrapper::after {
  content: 'ETH';
  font-family: Menlo;
  font-style: normal;
  font-weight: normal;
  padding-top: 4.5px;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.shimmer {
  color: grey;
  display:inline-block;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
  opacity: .4;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}